<template>
  <div class="container mx-auto px-4">
    <h1>Newsletter</h1>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>